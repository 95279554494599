.media-picture {
    position: relative;
    display: block;
    overflow: hidden;

    &__img {
        display: block;
        width: 100%;
        min-height: 100%;
        min-width: 100%;
    }

    &--lazy &__fixed-ratio {
        aspect-ratio: var(--fixed-width) / var(--fixed-height);
    }

    &--lazy &__img {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        &--loaded {
            opacity: 1;
        }
    }

    &--lazy {
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: calc(100% - 2 * #{$grid-gutter-width});
            top: 0;
            left: 0;
            height: 100%;
            margin-left: $grid-gutter-width;
            //background-color: $gray;
            transition: opacity 0.3s ease-in-out;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;
            transform: translateX(-100%);
            background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.15), transparent);
            animation: loading 750ms infinite;
        }
    }

    &--loaded {
        &::before {
            opacity: 0;
        }
        &::after {
            display: none;
        }
    }

    &--embedded {
        padding: 0;
        margin: 0;
        max-width: 100%;
    }

    &--embedded::before {
        width: 100%;
        margin-left: 0;
    }
}

@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}